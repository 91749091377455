<template>
  <div class="row">
    <ed-table
    :key="$root.$session.versao"
      strModel="Cadastro/Formulario"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :filter="formFiltros"
      :loading="loading"
      @register="onRegister"
      @edit="onEdit"
      @search="getFormularioPersolalizado"
    />
  </div>
</template>

<script>
import {
  EdTable,
  EdInputAutoComplete,
  EdModal,
} from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: { EdTable, EdInputAutoComplete, EdModal },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  mounted() {
    this.getFormularioPersolalizado();
  },
  created() {},
  data() {
    return {
      loading: false,
      boolExibirCadastroFuncionario: false,
      arrayHeaders: [
        {
          text: "Nome",
          sortable: true,
          collapse: 60,
          value: "strNome",
        },
        {
          text: "Código",
          sortable: true,
          info:'Utilize esse código para aplicar nos documentos como ASO, PPRA, PCMSO, etc',
          value: "strCodigo",
        },
        {
          text: "Vinculado Em",
          sortable: true,
          value: "strModelo",
          collapse: 5,
          collapseLabel: "Visualizar...",
        },
      ],
      arrayRows: [],
      objPagination: null,
      formFiltros:{
        boolMostrarEmpresa: true,
      }
    };
  },
  watch: {},
  computed: {},
  methods: {
    onRegister() {
      this.$router.push({
        name: "cadastro.formulario.register",
        params: {
          //intCadastroGeralEmpresaId: this.formData.intCadastroGeralEmpresaId,
        },
      });
    },

    onEdit(data) {
      this.$router.push({
        name: "cadastro.formulario.edit",
        params: {
          //intCadastroGeralEmpresaId: this.formData.intCadastroGeralEmpresaId,
          intId: data.intId,
        },
      });
    },

    getFormularioPersolalizado() {
      this.loading = true;
      this.arrayRows = [];


      this.$root.$request
        .get(
          "Cadastro/Formulario",
          this.formFiltros
        )
        .then((objResult) => {
          if (objResult.status == 200) {
            this.objPagination = objResult.result;
            this.loading = false;

            let arrayRows = [];

            objResult.result.data.forEach((objCampoPersonalizado) => {
              let strModelo = '<table class="table">';

              strModelo += "<tr>";
              strModelo += "<th>Nome</th>";
              strModelo += "</tr>";

              objCampoPersonalizado.modelos.forEach(
                (objCampoPersonalizadoModelo) => {
                  strModelo += "<tr>";
                  strModelo +=
                    "<td>" +
                    objCampoPersonalizadoModelo.tipo_modelo.strNome +
                    "</td>";
                  strModelo += "</tr>";
                }
              );

              let item = {
                intId: objCampoPersonalizado.intId,
                strNome: objCampoPersonalizado.strNome,
                strModelo: !objCampoPersonalizado.modelos.length
                  ? "---"
                  : strModelo,
                  strCodigo:'#formulario.'+(objCampoPersonalizado.intId)+'#',
                _item: objCampoPersonalizado,
              };

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          }
        });
    },
  },
};
</script>
